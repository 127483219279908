/**
 * table of content
 */

.content_index {
  ul {
    list-style-type: none;
  }
  li {
    line-height: 2.4em;

    position: relative;

    span {
      padding-right: 1em;

      background-color: white;
    }
    &:not(.head) {
      &:before {
        position: absolute;
        z-index: -1;
        bottom: 9px;
        left: 0;

        display: block;

        width: 100%;
        height: 1px;

        content: "";

        border-top: 1px dotted #eeeeee;
      }
    }

    span.page {
      position: absolute;
      right: 0;

      padding-left: 1em;
    }
  }
}
