.edit-container {
  position: relative;

  transition: border-color 0.25s ease;

  border-width: 1px;
  border-style: dashed;
  border-color: transparent;
  border-radius: 5px;
  /*
    &:hover:before {
      background-image: none;
      position: absolute;
      top: -100px;
      right: -100px;
      bottom: -100px;
      left: -100px;
    }*/

  a.edit {
    font-size: 1rem;

    position: absolute;
    z-index: 9999;
    right: 12px;

    transition: opacity 0.25s ease;

    opacity: 0;
  }
  &:hover {
    border-color: #0275d8;
    & > a.edit:first-child {
      opacity: 1;
    }

    /* .edit-container {


        position: relative;
        &: before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          background-image: url("/img/diagonal-dash.png");
          z-index: -1;

        }
      }*/
  }
}
