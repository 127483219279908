button {
  margin-left: 0rem;

  margin-rigth: 0.5rem;
  &.pull-right {
    margin-left: 0.5rem;

    margin-rigth: 0rem;
  }
}
