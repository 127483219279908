@media print {
  html {
    height: auto;
    min-height: auto;
    max-height: initial;

    a.back {
      display: none !important;
    }
  }
  body:before {
    position: fixed;

    display: block !important;

    width: 100vw;
    margin-top: 2em;

    content: "Bitte zum Drucken den Exportdialog verwenden.";
    text-align: center;

    color: black;
  }
  header,
  main,
  footer {
    display: none !important;
  }
}
