.product_tag {
  .title {
    position: relative;
    span {
      font-size: 20px;

      text-decoration: underline;
      text-transform: uppercase;
    }

    .new {
      float: right;
      i.fa {
        transition: opacity 0.25s ease;

        opacity: 0;
      }
    }

    &:hover {
      .new {
        i.fa {
          opacity: 1;
        }
      }
    }
  }
}
