table,
.table {
  border-width: 1px;
  thead,
  tbody,
  tfoot {
    th,
    td {
      padding: 0.25rem;

      vertical-align: middle;
    }
  }

  thead {
    tr:first-of-type {
      th {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }

    th {
      padding-right: 0;
      padding-left: 0;

      vertical-align: middle;

      border-bottom: 1px solid #dee2e6;
    }
  }

  tbody {
    tr.edit-container {
      td:first-of-type {
        a.edit {
          right: auto;
          left: 10px;
        }
      }

      td {
        .edit-container {
          padding: 0.5rem;
        }
      }
    }
  }
  a.edit {
    top: calc(50% - 12px);
  }
}


table.sortable {
  tr {
    transition: margin-top 0.25s ease;
    &.over {
      margin-top: 32px ;
    }
  }
}
