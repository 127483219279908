html {
  position: relative;

  min-height: 100%;
}

body {
  margin-bottom: 60px;
}

.footer {
  font-size: 0.9em;
  line-height: 60px;

  position: absolute;
  bottom: 0;

  width: 100%;
  height: 60px;

  .container {
    .row:first-of-type {
      border-top: 1px solid black;
    }
  }


  span.page {
    position: absolute;
    right: 0;
  }
}
