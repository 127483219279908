.position-table {
  thead {
    tr:first-of-type {
      th {
        border-top: 0;
      }
    }
  }

  tbody {
    tr {
      td:first-of-type {
        position: relative;
        a.edit {
          position: absolute;
          left: 5px;

          transition: opacity 0.25s ease;

          opacity: 0;
        }
      }
    }

    tr:hover {
      td:first-of-type {
        a.edit {
          opacity: 1;
        }
      }
    }
  }
}
