/** customer_index */

ul.alpha-shortcut {
  display: flex;
  flex-direction: row;

  padding: 0;
  li {
    flex: 1;

    list-style-type: none;

    text-align: center;
  }
}

li.customer > .accordion-toggle b {
  margin-left: 8px;
}


