nav.navbar {
  &:after,
  ul {
    display: none;
  }
}

.page,
.settings,
.overview,
.product_show,
.product_show,
.customer_index {
  nav.navbar {
    display: block;

    padding: 0;

    &:after {
      position: absolute;
      z-index: -1;
      bottom: 0;

      display: block;

      width: 100%;
      height: 2px;

      content: "";

      background-color: #eeeeee;
    }

    ul {
      display: flex;
    }

    .nav-item {
      padding: 0.25rem 0.5rem;

      transition: border-color 0.25s ease;

      border-width: 0 0 2px 0;
      border-style: solid;
      border-color: transparent;

      &:hover {
        border-color: $brand-red;
      }
      & + .nav-item {
        margin-left: 1rem;
      }
    }
  }
}
