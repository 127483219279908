html {
  a.back {
    font-size: 2rem;

    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    display: flex;

    width: 100px;
    height: 100px;

    transition: opacity 0.25s ease;
    text-decoration: none;

    opacity: 0;

    align-items: center;
    justify-content: center;
  }
  &:hover {
    a.back {
      opacity: 1;
    }
  }
}

ul.grains {
  display: flex;

  padding: 0;

  list-style-type: none;

  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  li {
    font-size: 12px;
    font-weight: bold;

    padding: 2px;

    text-align: center;

    opacity: 0.5;
    background-color: #eeeeee;

    flex-grow: 1;
    &.available {
      opacity: 1;
      color: white;
      background-color: $brand-red;
    }

    & + li {
      margin-left: 3px;
    }
  }
}

ul.properties {
  padding: 0;

  list-style-type: none;
  li {
    text-align: left;

    border-bottom: 1px solid black;

    em {
      font-style: normal;

      display: inline-block;

      width: 45%;
    }
  }
}

ul.applications {
  padding: 0;

  list-style-type: none;

  li {
    position: relative;

    border-bottom: 1px solid black;

    img {
      position: absolute;
      top: 3px;
      right: 0;

      display: block;

      width: 12px;
      height: 12px;
    }
  }
}

ul.properties,
ul.applications {
  &,
  & b {
    font-size: 12px;
  }
  li:first-of-type {
    border-top: 2px solid black;
  }
}

#appbundle_product_grain_grains {
  display: flex;
  flex-direction: column;

  height: 250px;

  flex-wrap: wrap;

  & > .checkbox {
    width: 25%;
  }
}

.product_show {
  .edit-container.adjustment {
    border: 1px dashed #dc3545;
  }
}

.product_export {
  .edit-container.adjustment {
    border: 1px solid transparent !important;
  }
}

.product-description [class*="col-"] {
  margin-bottom: 40px;
}

.product-articlenumber [class*="col-"] {
  position: absolute;
  bottom: 0;
}
