.static.agb {
  .section {
    font-size: 0.875em;

    margin-bottom: 2rem;

    b {
      display: block;

      margin-bottom: 0.75rem;

      text-align: center;
    }

    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;

      li {
        position: relative;

        padding-left: 2rem;

        & + li {
          margin-top: 1rem;
        }


        li:before,
        span {
          position: absolute;
          top: 0;
          left: 0;
        }

        li:before {
          content: "-";
        }
      }
    }
  }
  &.short {
    font-size: 0.8rem;
    .section {
      line-height: 0.9rem;
      p {
        margin-bottom: 0;
      }
      ul {
        li + li {
          margin-top: 0;
        }
      }
    }
  }
}
