.position-table {
  tfoot a {
    transition: opacity 0.2s ease;

    opacity: 0;
  }

  &:hover {
    tfoot a {
      opacity: 1;
    }
  }
}
