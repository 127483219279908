header {
  position: relative;

  margin-bottom: 1em;
  padding: 50px 0 1em 0;

  color: #ffffff;
  .title {
    padding-top: 15px;
    > * {
      font-size: 1.5rem;
      font-weight: bold;

      margin: 0;

      color: black;
    }
  }

  img#logo {
    position: absolute;
    top: 1px;
    right: 16px;

    pointer-events: none;
  }

  .subtitle {
    font-size: 1.4rem;

    color: black;
  }

  .edit-container {
    min-height: 100px;
    margin-bottom: 2rem;
  }
}

.product_export {
  header {
    .row:last-of-type [class*="col-"] {
      &:before {
        position: absolute;
        right: 15px;
        bottom: 0;
        left: 15px;

        height: 2px;

        content: "";

        background-color: #eeeeee;
      }
    }
  }
}
