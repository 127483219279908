@charset "UTF-8";
html {
  height: 360mm;
  /**
  297mm höhe einer din-a4 seite,
  0.57142857142 wkhtmltopdf zoomfaktor
  = 519.750000008mm
  **/
  min-height: 360mm;
  max-height: 360mm; }

body {
  color: black; }

table thead th {
  text-align: center; }

@font-face {
  font-family: "Freestyle";
  src: url("../fonts/freestyle.ttf"); }

.container {
  padding-right: 50px;
  padding-left: 100px; }

button {
  margin-left: 0rem;
  margin-rigth: 0.5rem; }
  button.pull-right {
    margin-left: 0.5rem;
    margin-rigth: 0rem; }

.edit-container {
  position: relative;
  transition: border-color 0.25s ease;
  border-width: 1px;
  border-style: dashed;
  border-color: transparent;
  border-radius: 5px;
  /*
    &:hover:before {
      background-image: none;
      position: absolute;
      top: -100px;
      right: -100px;
      bottom: -100px;
      left: -100px;
    }*/ }
  .edit-container a.edit {
    font-size: 1rem;
    position: absolute;
    z-index: 9999;
    right: 12px;
    transition: opacity 0.25s ease;
    opacity: 0; }
  .edit-container:hover {
    border-color: #0275d8;
    /* .edit-container {


        position: relative;
        &: before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          background-image: url("/img/diagonal-dash.png");
          z-index: -1;

        }
      }*/ }
    .edit-container:hover > a.edit:first-child {
      opacity: 1; }

html {
  position: relative;
  min-height: 100%; }

body {
  margin-bottom: 60px; }

.footer {
  font-size: 0.9em;
  line-height: 60px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; }
  .footer .container .row:first-of-type {
    border-top: 1px solid black; }
  .footer span.page {
    position: absolute;
    right: 0; }

header {
  position: relative;
  margin-bottom: 1em;
  padding: 50px 0 1em 0;
  color: #ffffff; }
  header .title {
    padding-top: 15px; }
    header .title > * {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
      color: black; }
  header img#logo {
    position: absolute;
    top: 1px;
    right: 16px;
    pointer-events: none; }
  header .subtitle {
    font-size: 1.4rem;
    color: black; }
  header .edit-container {
    min-height: 100px;
    margin-bottom: 2rem; }

.product_export header .row:last-of-type [class*="col-"]:before {
  position: absolute;
  right: 15px;
  bottom: 0;
  left: 15px;
  height: 2px;
  content: "";
  background-color: #eeeeee; }

nav.navbar:after,
nav.navbar ul {
  display: none; }

.page nav.navbar,
.settings nav.navbar,
.overview nav.navbar,
.product_show nav.navbar,
.product_show nav.navbar,
.customer_index nav.navbar {
  display: block;
  padding: 0; }
  .page nav.navbar:after,
  .settings nav.navbar:after,
  .overview nav.navbar:after,
  .product_show nav.navbar:after,
  .product_show nav.navbar:after,
  .customer_index nav.navbar:after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #eeeeee; }
  .page nav.navbar ul,
  .settings nav.navbar ul,
  .overview nav.navbar ul,
  .product_show nav.navbar ul,
  .product_show nav.navbar ul,
  .customer_index nav.navbar ul {
    display: flex; }
  .page nav.navbar .nav-item,
  .settings nav.navbar .nav-item,
  .overview nav.navbar .nav-item,
  .product_show nav.navbar .nav-item,
  .product_show nav.navbar .nav-item,
  .customer_index nav.navbar .nav-item {
    padding: 0.25rem 0.5rem;
    transition: border-color 0.25s ease;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: transparent; }
    .page nav.navbar .nav-item:hover,
    .settings nav.navbar .nav-item:hover,
    .overview nav.navbar .nav-item:hover,
    .product_show nav.navbar .nav-item:hover,
    .product_show nav.navbar .nav-item:hover,
    .customer_index nav.navbar .nav-item:hover {
      border-color: #d21500; }
    .page nav.navbar .nav-item + .nav-item,
    .settings nav.navbar .nav-item + .nav-item,
    .overview nav.navbar .nav-item + .nav-item,
    .product_show nav.navbar .nav-item + .nav-item,
    .product_show nav.navbar .nav-item + .nav-item,
    .customer_index nav.navbar .nav-item + .nav-item {
      margin-left: 1rem; }

table,
.table {
  border-width: 1px; }
  table thead th,
  table thead td,
  table tbody th,
  table tbody td,
  table tfoot th,
  table tfoot td,
  .table thead th,
  .table thead td,
  .table tbody th,
  .table tbody td,
  .table tfoot th,
  .table tfoot td {
    padding: 0.25rem;
    vertical-align: middle; }
  table thead tr:first-of-type th,
  .table thead tr:first-of-type th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  table thead th,
  .table thead th {
    padding-right: 0;
    padding-left: 0;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6; }
  table tbody tr.edit-container td:first-of-type a.edit,
  .table tbody tr.edit-container td:first-of-type a.edit {
    right: auto;
    left: 10px; }
  table tbody tr.edit-container td .edit-container,
  .table tbody tr.edit-container td .edit-container {
    padding: 0.5rem; }
  table a.edit,
  .table a.edit {
    top: calc(50% - 12px); }

table.sortable tr {
  transition: margin-top 0.25s ease; }
  table.sortable tr.over {
    margin-top: 32px; }

.position-table tfoot a {
  transition: opacity 0.2s ease;
  opacity: 0; }

.position-table:hover tfoot a {
  opacity: 1; }

.modal-body .form-group > .horizontal {
  display: flex;
  margin-top: 6px; }
  .modal-body .form-group > .horizontal .radio label {
    margin-bottom: 0; }
  .modal-body .form-group > .horizontal .radio + .radio {
    margin-left: 1rem; }

.modal-body .grain-group > .form-group {
  margin: 0; }
  .modal-body .grain-group > .form-group + .form-group {
    border-top: 1px solid #eeeeee; }
  .modal-body .grain-group > .form-group .form-group {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .modal-body .grain-group > .form-group > label {
    display: none; }
  .modal-body .grain-group > .form-group > div {
    display: flex; }
    .modal-body .grain-group > .form-group > div .form-group {
      flex: 1; }
    .modal-body .grain-group > .form-group > div [readonly="readonly"] {
      font-weight: bold;
      padding-left: 0;
      pointer-events: none;
      border: 0;
      outline: none;
      background-color: transparent; }

.modal-body label.control-label[for="appbundle_product_picture"] {
  display: block; }

.radio > label.font-weight-bold {
  font-weight: normal !important; }

.page.image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

body.page {
  position: relative; }
  body.page .caption {
    font-size: 1.8em;
    position: absolute;
    top: 1468px;
    left: 177px;
    display: flex;
    width: 886px;
    height: 156px;
    padding-left: 30px;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center; }

.position-table thead tr:first-of-type th {
  border-top: 0; }

.position-table tbody tr td:first-of-type {
  position: relative; }
  .position-table tbody tr td:first-of-type a.edit {
    position: absolute;
    left: 5px;
    transition: opacity 0.25s ease;
    opacity: 0; }

.position-table tbody tr:hover td:first-of-type a.edit {
  opacity: 1; }

.product h1 {
  /* background-color: #ddd;*/
  padding: 0.25rem 0.5rem; }
  .product h1 small {
    font-size: 0.6em;
    display: block; }

.product img {
  margin-bottom: 1rem; }

.product .position-table {
  font-size: 0.75rem;
  padding: 0.25rem; }

.product .grain,
.product .grain-vpe,
.product .position {
  border-left: 1px solid #dee2e6; }
  .product .grain a.edit,
  .product .grain-vpe a.edit,
  .product .position a.edit {
    top: calc(50% - 12px);
    right: 0; }

.product_tag .title {
  position: relative; }
  .product_tag .title span {
    font-size: 20px;
    text-decoration: underline;
    text-transform: uppercase; }
  .product_tag .title .new {
    float: right; }
    .product_tag .title .new i.fa {
      transition: opacity 0.25s ease;
      opacity: 0; }
  .product_tag .title:hover .new i.fa {
    opacity: 1; }

.overview .categories li {
  list-style-type: none; }

.overview .categories > li {
  position: relative; }

.overview .categories span.drag {
  cursor: pointer; }

.overview .categories a.new {
  margin-left: 5px; }
  .overview .categories a.new i {
    margin-right: 10px; }

.overview .categories input[type="checkbox"] {
  margin-right: 10px;
  margin-left: 5px; }

ul.categories,
ul.customers {
  padding-left: 0; }

ul.categories li,
ul.customers li,
ul.pricelists li,
li.pricelist ul.products li {
  line-height: 2.2rem;
  list-style-type: none; }

li.product,
li.pricelist,
li.customer,
li.category {
  position: relative; }
  li.product .options,
  li.pricelist .options,
  li.customer .options,
  li.category .options {
    position: absolute;
    top: 0;
    right: 5px; }
    li.product .options > * + *,
    li.pricelist .options > * + *,
    li.customer .options > * + *,
    li.category .options > * + * {
      margin-left: 10px; }

li.category > div {
  padding-left: 32px; }

li.customer,
li.category {
  background-color: #eeeeee; }
  li.customer > input[type="checkbox"],
  li.category > input[type="checkbox"] {
    position: absolute;
    top: 12px;
    left: 5px; }
  li.customer small,
  li.category small {
    margin-left: 1rem; }
  li.customer span,
  li.customer a,
  li.category span,
  li.category a {
    line-height: 1.8; }
  li.customer ul.products,
  li.customer ul.pricelists,
  li.category ul.products,
  li.category ul.pricelists {
    padding-left: 28px;
    background-color: #ffffff; }
    li.customer ul.products li,
    li.customer ul.pricelists li,
    li.category ul.products li,
    li.category ul.pricelists li {
      position: relative; }
      li.customer ul.products li span,
      li.customer ul.products li a,
      li.customer ul.pricelists li span,
      li.customer ul.pricelists li a,
      li.category ul.products li span,
      li.category ul.products li a,
      li.category ul.pricelists li span,
      li.category ul.pricelists li a {
        line-height: 1.8; }
      li.customer ul.products li + li,
      li.customer ul.pricelists li + li,
      li.category ul.products li + li,
      li.category ul.pricelists li + li {
        /*margin-top: 0.5em;*/
        border-top: 1px dotted #eeeeee; }

ul.products > li {
  border-top: 1px dotted #eeeeee; }

.sortable li {
  padding-top: 0;
  transition: padding-top 0.25s ease; }
  .sortable li.over:not(.dragged) {
    /*padding-top: 32px;*/ }

.sortable a.drag {
  pointer-events: none; }

.sortable li:only-child a.drag {
  display: none; }

li.customer + li.customer {
  margin-top: 0.5em; }

li.category + li.category {
  margin-top: 0.5em; }

li {
  transition: background-color 0.25s ease; }

li.highlighted {
  background-color: #d7eed7; }

html a.back {
  font-size: 2rem;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: 100px;
  height: 100px;
  transition: opacity 0.25s ease;
  text-decoration: none;
  opacity: 0;
  align-items: center;
  justify-content: center; }

html:hover a.back {
  opacity: 1; }

ul.grains {
  display: flex;
  padding: 0;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap; }
  ul.grains li {
    font-size: 12px;
    font-weight: bold;
    padding: 2px;
    text-align: center;
    opacity: 0.5;
    background-color: #eeeeee;
    flex-grow: 1; }
    ul.grains li.available {
      opacity: 1;
      color: white;
      background-color: #d21500; }
    ul.grains li + li {
      margin-left: 3px; }

ul.properties {
  padding: 0;
  list-style-type: none; }
  ul.properties li {
    text-align: left;
    border-bottom: 1px solid black; }
    ul.properties li em {
      font-style: normal;
      display: inline-block;
      width: 45%; }

ul.applications {
  padding: 0;
  list-style-type: none; }
  ul.applications li {
    position: relative;
    border-bottom: 1px solid black; }
    ul.applications li img {
      position: absolute;
      top: 3px;
      right: 0;
      display: block;
      width: 12px;
      height: 12px; }

ul.properties,
ul.properties b,
ul.applications,
ul.applications b {
  font-size: 12px; }

ul.properties li:first-of-type,
ul.applications li:first-of-type {
  border-top: 2px solid black; }

#appbundle_product_grain_grains {
  display: flex;
  flex-direction: column;
  height: 250px;
  flex-wrap: wrap; }
  #appbundle_product_grain_grains > .checkbox {
    width: 25%; }

.product_show .edit-container.adjustment {
  border: 1px dashed #dc3545; }

.product_export .edit-container.adjustment {
  border: 1px solid transparent !important; }

.product-description [class*="col-"] {
  margin-bottom: 40px; }

.product-articlenumber [class*="col-"] {
  position: absolute;
  bottom: 0; }

/** customer_index */
ul.alpha-shortcut {
  display: flex;
  flex-direction: row;
  padding: 0; }
  ul.alpha-shortcut li {
    flex: 1;
    list-style-type: none;
    text-align: center; }

li.customer > .accordion-toggle b {
  margin-left: 8px; }

.static.agb .section {
  font-size: 0.875em;
  margin-bottom: 2rem; }
  .static.agb .section b {
    display: block;
    margin-bottom: 0.75rem;
    text-align: center; }
  .static.agb .section ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .static.agb .section ul li {
      position: relative;
      padding-left: 2rem; }
      .static.agb .section ul li + li {
        margin-top: 1rem; }
      .static.agb .section ul li li:before,
      .static.agb .section ul li span {
        position: absolute;
        top: 0;
        left: 0; }
      .static.agb .section ul li li:before {
        content: "-"; }

.static.agb.short {
  font-size: 0.8rem; }
  .static.agb.short .section {
    line-height: 0.9rem; }
    .static.agb.short .section p {
      margin-bottom: 0; }
    .static.agb.short .section ul li + li {
      margin-top: 0; }

.static.about-ihg .row {
  padding-left: 1rem; }

.static.about-ihg b.title {
  font-size: 1.5rem;
  font-weight: bold; }

.static.about-ihg .img-caption {
  font-size: 0.875rem; }
  .static.about-ihg .img-caption small {
    font-size: 0.75rem; }

.static.about-ihg ul.list-style-dash {
  list-style-type: none; }
  .static.about-ihg ul.list-style-dash li {
    position: relative; }
    .static.about-ihg ul.list-style-dash li + li {
      margin-top: 1rem; }
    .static.about-ihg ul.list-style-dash li:before {
      position: absolute;
      left: -20px;
      content: "-"; }

.static.about-ihg .mr-abrasives {
  font-family: "Freestyle", Serif;
  font-size: 2rem; }

/**
 * table of content
 */
.content_index ul {
  list-style-type: none; }

.content_index li {
  line-height: 2.4em;
  position: relative; }
  .content_index li span {
    padding-right: 1em;
    background-color: white; }
  .content_index li:not(.head):before {
    position: absolute;
    z-index: -1;
    bottom: 9px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    content: "";
    border-top: 1px dotted #eeeeee; }
  .content_index li span.page {
    position: absolute;
    right: 0;
    padding-left: 1em; }

@media print {
  html {
    height: auto;
    min-height: auto;
    max-height: initial; }
    html a.back {
      display: none !important; }
  body:before {
    position: fixed;
    display: block !important;
    width: 100vw;
    margin-top: 2em;
    content: "Bitte zum Drucken den Exportdialog verwenden.";
    text-align: center;
    color: black; }
  header,
  main,
  footer {
    display: none !important; } }
