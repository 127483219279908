.modal-body {
  .form-group {
    & > .horizontal {
      display: flex;

      margin-top: 6px;

      .radio {
        label {
          margin-bottom: 0;
        }
        & + .radio {
          margin-left: 1rem;
        }
      }
    }
  }

  .grain-group {
    & > .form-group {
      margin: 0;
      & + .form-group {
        border-top: 1px solid #eeeeee;
      }
      & .form-group {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      & > label {
        display: none;
      }
      & > div {
        display: flex;

        .form-group {
          flex: 1;
        }
        [readonly="readonly"] {
          font-weight: bold;

          padding-left: 0;

          pointer-events: none;

          border: 0;
          outline: none;
          background-color: transparent;
        }
      }
    }
  }

  label.control-label[for="appbundle_product_picture"] {
    display: block;
  }
}
