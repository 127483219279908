.static.about-ihg {
  .row {
    padding-left: 1rem;
  }
  b.title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .img-caption {
    font-size: 0.875rem;

    small {
      font-size: 0.75rem;
    }
  }

  ul.list-style-dash {
    list-style-type: none;
    li {
      position: relative;

      & + li {
        margin-top: 1rem;
      }

      &:before {
        position: absolute;
        left: -20px;

        content: "-";
      }
    }
  }

  .mr-abrasives {
    font-family: "Freestyle", Serif;
    font-size: 2rem;
  }
}
