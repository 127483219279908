.overview {
  .categories {
    li {
      list-style-type: none;
    }

    & > li {
      position: relative;
    }

    span.drag {
      cursor: pointer;
    }

    a.new {
      margin-left: 5px;
      i {
        margin-right: 10px;
      }
    }

    input[type="checkbox"] {
      margin-right: 10px;
      margin-left: 5px;
    }
  }
}

ul.categories,
ul.customers {
  padding-left: 0;
}

ul.categories,
ul.customers,
ul.pricelists,
li.pricelist ul.products {
  li {
    line-height: 2.2rem;

    list-style-type: none;
  }
}

li.product,
li.pricelist,
li.customer,
li.category {
  position: relative;

  .options {
    position: absolute;
    top: 0;
    right: 5px;

    & > * + * {
      margin-left: 10px;
    }
  }
}


li.category > div {
  padding-left: 32px;
}

li.customer,
li.category {
  background-color: #eeeeee;

  & > input[type="checkbox"] {
    position: absolute;
    top: 12px;
    left: 5px;
  }



  small {
    margin-left: 1rem;
  }

  span,
  a {
    line-height: 1.8;
  }

  ul.products,
  ul.pricelists {
    padding-left: 28px;
    //  border-top: 1px solid #eeeeee;

    background-color: #ffffff;
    li {
      position: relative;
      span,
      a {
        line-height: 1.8;
      }
      & + li {
        /*margin-top: 0.5em;*/
        border-top: 1px dotted #eeeeee;
      }
    }
  }
}

ul.products {
  > li {
    border-top: 1px dotted #eeeeee;
  }
}

.sortable {
  li {
    padding-top: 0;

    transition: padding-top 0.25s ease;

    &.over:not(.dragged) {
      /*padding-top: 32px;*/
    }
  }
  a.drag {
    pointer-events: none;
  }
  li:only-child {
    a.drag {
      display: none;
    }
  }
}

li.customer + li.customer {
  margin-top: 0.5em;
}

li.category + li.category {
  margin-top: 0.5em;
}


li {
  transition: background-color 0.25s ease;
}

li.highlighted {
  background-color: #d7eed7;
}
