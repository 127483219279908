.container {
  padding-right: 50px;
  padding-left: 100px;
}

@import "button";
@import "edit-container";
@import "footer";
@import "header";
@import "navigation";
@import "table";
@import "position";
@import "modal";


.radio > label.font-weight-bold {
  font-weight: normal !important;
}
