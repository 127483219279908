.page.image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body.page {
  position: relative;
  .caption {
    font-size: 1.8em;

    position: absolute;
    top: 1468px;
    left: 177px;

    display: flex;

    width: 886px;
    height: 156px;
    padding-left: 30px;

    vertical-align: middle;
    white-space: nowrap;

    align-items: center;
  }
}

