html {
  height: 360mm;
  /**
  297mm höhe einer din-a4 seite,
  0.57142857142 wkhtmltopdf zoomfaktor
  = 519.750000008mm
  **/
  min-height: 360mm;
  max-height: 360mm;
}

body {
  color: black;
}

table thead th {
  text-align: center;
}

$brand-red: #d21500;

@font-face {
  font-family: "Freestyle";

  src: url("../fonts/freestyle.ttf");
}
