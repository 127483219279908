.product {
  h1 {
    /* background-color: #ddd;*/
    padding: 0.25rem 0.5rem;
    small {
      font-size: 0.6em;

      display: block;
    }
  }

  img {
    margin-bottom: 1rem;
  }

  .position-table {
    font-size: 0.75rem;

    padding: 0.25rem;
  }
  .grain,
  .grain-vpe,
  .position {
    border-left: 1px solid #dee2e6;
    a.edit {
      top: calc(50% - 12px);
      right: 0;
    }
  }
}
